<template>
  <div class="self-type">
    <div v-for="(item,index) in list" :key="item.menu_id" :class="[item.parent_menu_id==0?'self-type-title':'self-type-item',{'bg-active':index==hoverIndex&&item.parent_menu_id!=0}]" @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1"  @click="clickEvent(item.parent_menu_id!=0,item.menu_name)">
      {{item.menu_name}}
    </div>
  </div>
</template>
<script>
  export default {
    name:"selfType",
    props:{
      // title:String,
      list:Array
    },
    data(){
      return {
        hoverIndex:-1,
        // selfList:[],
        // title:""
      }
    },

    methods:{
      clickEvent(bool,e){
        console.log(bool)
        bool&&this.$emit("on-click",e);//将方法抛出给父组件，在父组件中用@on-click来使用
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "@/assets/style/basic.scss";
  .self-type{
    &-title{
      word-break: break-all;
      font-size: 15px;
      margin:5px 0 10px;
    }
    &-item{
      cursor: pointer;
      width: 100%;
      padding: 7px;
      box-sizing:border-box;
      border:1px solid #e7e7e7;
      margin-top: 5px;
      border-radius: 4px;
      &.bg-active{
        @include background-main-color($theme_sky);
        border:1px solid #fff;
        color: #fff;
      }
    }
  }
</style>
